(function($) {

    var h = $('.detail-accordion-half div').height();
    var closedHeight = 160;
    var additionalHeight = 70;

    // Detail accordion
    $(document).on('click', '.detail-accordion h3', function(e) {
        if($(this).parent().hasClass('open')) {
            $('.detail-accordion-half > div').stop().animate({
                height: closedHeight
            }, 500);
            $('.detail-accordion h3 i').removeClass('fa fa-angle-up').addClass('fa fa-angle-down');
            $('.detail-accordion-half').removeClass('open')
        } else {
            var element = $('.detail-accordion-half > div');
            var curHeight = element.height(); // Get Default Height
            var autoHeight = element.css('height', 'auto').height() + additionalHeight; // Get Auto Height
            element.height(curHeight);
            element.stop().animate({
                height: autoHeight,
            }, 500);
            $('.detail-accordion h3 i').removeClass('fa fa-angle-down').addClass('fa fa-angle-up');
            $('.detail-accordion-half').slideDown().addClass('open');
        }
        e.preventDefault();
    })

    $(document).on('click', '.detail-accordion-opener span', function(e) {
        if($('.detail-accordion-half').hasClass('open')) {
            $('.detail-accordion-half > div').stop().animate({
                height: closedHeight
            }, 500);
            $('.detail-accordion h3 i').removeClass('fa fa-angle-up').addClass('fa fa-angle-down');
            $('.detail-accordion-half').removeClass('open')
        } else {
            var element = $('.detail-accordion-half > div');
            var curHeight = element.height(); // Get Default Height
            var autoHeight = element.css('height', 'auto').height() + additionalHeight; // Get Auto Height
            element.height(curHeight);
            element.stop().animate({
                height: autoHeight,
            }, 500);
            $('.detail-accordion h3 i').removeClass('fa fa-angle-down').addClass('fa fa-angle-up');
            $('.detail-accordion-half').slideDown().addClass('open');
        }
        e.preventDefault();
    })

    // Slider images
    $('.detail-image img').load(function() {
        $('.detail-image').removeClass('loading');
    });

    $(document).on('click', '[data-toggle="change-slider-image"]', function(e) {
        $('.detail-image img').prop('src', $(this).prop('href'))
        $('.detail-image').addClass('loading');
        e.preventDefault();
    })

    detailSlickSlider();

    function detailSlickSlider() {
        var nextable = true;
        $(document).on('click', '.detail-page .slick-next', function (e) {
            if (!nextable) {
                return;
            }

            var $img = $('.detail-image img');
            var imgName = $img.prop('src');

            var $currentImg = $('.detail-image-slider a[href="' + imgName + '"]');
            var $nextImg = $currentImg.next();
            if (!$nextImg) {
                $nextImg = $('.detail-image-slider').first();
            }

            $img.prop('src', $nextImg.prop('href'));
            nextable = false;
            $('.detail-image-slider').slick('slickNext');
        });

        var prevable = true;
        $(document).on('click', '.detail-page .slick-prev', function (e) {
            if (!prevable) {
                return;
            }

            var $img = $('.detail-image img');
            var imgName = $img.prop('src');

            var $currentImg = $('.detail-image-slider a[href="' + imgName + '"]');
            var $prevImg = $currentImg.prev();
            if (!$prevImg) {
                $prevImg = $('.detail-image-slider').first();
            }

            $img.prop('src', $prevImg.prop('href'));
            prevable = false;
            $('.detail-image-slider').slick('slickPrev');
        });

        $('.detail-image-slider').on('afterChange', function(e, slick, currentSlide, nextSlide) {
            prevable = true;
            nextable = true;
        });
    }

})(jQuery)
