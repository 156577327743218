$(function () {
    var partnerVideoHelper = function () {
        var modal = $(".hotels-modal");
        var zoom = $(".hotels-zoom");
        var btn = $(".hotels-button");
        var close = $(".hotels-close");

        btn.click(function (e) {
            var type = $(e.currentTarget).data('type');
            $('.helper-content').each(function (key, item) {
                console.log(item);
                if (!$(item).hasClass('hidden')) {
                    $(item).addClass('hidden');
                }
            });
            $('.' + type + '-content').toggleClass('hidden');
            modal.show("drop", {direction: "right"}, "slow")
        });

        close.click(function () {
            modal.hide("drop", {direction: "right"}, "slow", function () {
                if (modal.hasClass('zoom')) {
                    modal.removeClass('zoom');
                }
            });
        });

        zoom.click(function () {
            if (modal.hasClass('zoom')) {
                modal.removeClass('zoom');
            } else {
                modal.addClass('zoom');
            }
        });
    };

    partnerVideoHelper();

    $('.wyswyg').summernote({
        height: 180,
        toolbar: [
            ['style', ['bold', 'italic']],
            ['paragraph', ['ol', 'ul']],
            ['misc', ['undo', 'redo']]
        ],
        callbacks: {
            onPaste: function (e) {
                var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
                e.preventDefault();
                setTimeout( function(){
                    document.execCommand( 'insertText', false, bufferText );
                }, 10 );
            }
        }
    });

    $('.admin-wyswyg').summernote({
        height: 180,
        toolbar: [
            ['style', ['fontname', 'fontsize', 'color', 'bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
            ['insert', ['picture', 'link', 'video', 'table', 'hr']],
            ['paragraph', ['style', 'ol', 'ul', 'paragraph', 'height']],
            ['misc', ['fullscreen', 'undo', 'redo', 'codeview']]
        ],
        callbacks: {
            onPaste: function (e) {
                var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
                e.preventDefault();
                setTimeout( function(){
                    document.execCommand( 'insertText', false, bufferText );
                }, 10 );
            }
        }
    });

    $('.mobile-menu .nav-icon').click(function () {
        if ($('.mobile-searchform .search-icon').hasClass('open')) {
            $('.mobile-searchform .search-icon').click();
        }
        $(this).toggleClass('open');
        $('.toggle-menu').slideToggle();
        if($(window).scrollTop() >= 1) {
            setTimeout(function () {
                var wrapperHeight = $('.header-wrapper').height()+20;
                $('body.scrolled').css('padding-top', (wrapperHeight)+'px');
            }, 500);

        }

    });

    $('.mobile-mapsearch .nav-icon').click(function () {
        $(this).toggleClass('open');
        $('.toggle-mapsearch').slideToggle();
    });

    $('.slick-arrow').css('display','inherit');

    $(document).on('click', '.detail-features .more', function (e) {
        var category = $(this).data('category');
        $(this).remove();
        $('.service-category-'+category).removeClass('hidden');
    });

    $(document).on('click', '.mobile-searchform .search-icon, .mobile-searchform .mobile-searchform-label', function (e) {
        if ($('.mobile-menu .nav-icon').hasClass('open')) {
            $('.mobile-menu .nav-icon').click();
        }
        $(this).toggleClass('open');
        if (!$('.toggle-searchform').hasClass('open')) {
            $('.toggle-searchform').removeClass('closed');
            $('.toggle-searchform').addClass('open');
        }
        else {
            $('.toggle-searchform').removeClass('open');
            $('.toggle-searchform').addClass('closed');
        }
        if($(window).scrollTop() >= 1) {
            setTimeout(function () {
                var wrapperHeight = $('.header-wrapper').height()+20;
                $('body.scrolled').css('padding-top', (wrapperHeight)+'px');
            }, 100);
        }
    });

    $('.filters').removeClass('hidden');

    $('.slider-home').slick({
        infinite: true,
        prevArrow: '<span class="arrow-left"><i class="icon-chevron-left"></i></span>',
        nextArrow: '<span class="arrow-right"><i class="icon-chevron-right"></i></span>',
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000
    });

    $('.slider-list-package').slick({
        dots: true,
        infinite: false,
        prevArrow: '<span class="arrow-left"><i class="icon-chevron-left"></i></span>',
        nextArrow: '<span class="arrow-right"><i class="icon-chevron-right"></i></span>',
        slidesToShow: 2,
        slidesToScroll: 2,
    });


    $('.js-list-offer-slider').slick({
        dots: true,
        infinite: false,
        prevArrow: '<span class="arrow-left"><i class="icon-chevron-left"></i></span>',
        nextArrow: '<span class="arrow-right"><i class="icon-chevron-right"></i></span>',
        slidesToShow: 2,
        slidesToScroll: 2
    })

    $('.detail-image-slider').fadeIn('slow').slick({
        infinite: true,
        // prevArrow: '<span class="arrow-left"><i class="icon-chevron-left"></i></span>',
        // nextArrow: '<span class="arrow-right"><i class="icon-chevron-right"></i></span>',
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        touchThreshold: 10,
        variableWidth: true
    });

    // $('.main-offers-content').slimScroll({
    //     height: '420px',
    //     color: '#AAA',
    //     alwaysVisible: true,
    // });

    $('a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
        $('.detail-image-slider').slick('setPosition');
    });

    $('.slider-cities').slick({
        infinite: true,
        prevArrow: '<span class="arrow-left"><i class="icon-chevron-left"></i></span>',
        nextArrow: '<span class="arrow-right"><i class="icon-chevron-right"></i></span>',
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });

    $(document).on('click', '.filter-title', function () {
        el = $(this).parent();
        el.find('.filter-content').stop().slideToggle('normal', function () {
            el.toggleClass('open');
        });
    });

    $(document).on('click', '.more-offer-link:not([data-target])', function (e) {
        $(this).toggleClass('active');
        $(this).closest('.item').find('.more-offer').toggleClass('open');
        e.preventDefault();
    });

    $(document).on('click', function (e) {
        if (!$(e.target).hasClass('more-offer') && $(e.target).closest('.more-offer').length == 0 && !$(e.target).hasClass('more-offer-link') && $(e.target).closest('.more-offer-link').length == 0) {
            $('.more-offer').removeClass('open');
            $('.more-offer-link').removeClass('active');
        }
    });

    var daterangePickerLocale = {
        format: 'YYYY. MM. DD.',
        separator: ' - ',
        applyLabel: 'Rendben',
        cancelLabel: 'Mégsem',
        fromLabel: 'Mettől',
        toLabel: 'Meddig',
        customRangeLabel: 'Egyedi',
        daysOfWeek: [
            'V',
            'H',
            'K',
            'Sze',
            'Cs',
            'P',
            'Szo',
        ],
        monthNames: [
            'Január',
            'Február',
            'Március',
            'Április',
            'Május',
            'Június',
            'Július',
            'Augusztus',
            'Szeptember',
            'Október',
            'November',
            'December',
        ],
        firstDay: 1,
    };

    $('.daterange').daterangepicker({
        autoApply: true,
        autoUpdateInput: true,
        locale: daterangePickerLocale,
        minDate: new Date(),
    });

    $(document).on('click', '.review-form a', function() {
        var r = $(this).closest('.review-form').find('.review-form-rows > div:last').clone();
        $(this).closest('.review-form').find('.review-form-rows > div:last').after(r);
        $('input', r).val('').focus();
        // $('input', r).focus();
    });

    $(document).on('click', '.review-form-rows button', function() {
        $(this).parent().remove();
    });

    $(document).on('click', '.are-you-sure', function () {
        var $trigger = $(this);
        $('.send-form').on('click', function () {
            $trigger.parent().submit();
        });
    });

    $(document).on('click', '.loading_on_submit', function () {
        $('.loading_overlay').removeClass('hidden');
    });

    $(document).on('change', '[data-trigger="js-adult-selector"], [data-trigger="js-child-selector"]', function () {
        var trigger = $(this).data('trigger');
        var value = $(this).val();
        $('[data-listen="' + trigger + '"]').text(value);
    });

    $(document).on('click', function(e) {
        if (($(e.target).hasClass('js-persons-drodpown') || $(e.target).closest('.js-persons-drodpown').length > 0) && $(e.target).closest('.persons-dropdown__menu').length == 0) {
            $('.js-persons-drodpown').toggleClass('open');
        }

        if (($(e.target).hasClass('js-persons-drodpown-site') ||
            $(e.target).closest('.js-persons-drodpown-site').length > 0) &&
            $(e.target).closest('.persons-dropdown__menu').length == 0) {
            $('.js-persons-drodpown-site').toggleClass('open');
        }

        if ($(e.target).closest('.js-persons-drodpown').length == 0 && $(e.target).closest('.persons-dropdown__menu').length == 0) {
            // $('.js-persons-drodpown').removeClass('open');
        }

        if ($(e.target).closest('.js-persons-drodpown-site').length == 0 && $(e.target).closest('.persons-dropdown__menu').length == 0) {
            // $('.js-persons-drodpown-site').removeClass('open');
        }
    });

    $(document).on('click', '.js-persons-drodpown-submit', function () {
        $('.js-persons-drodpown, .js-persons-drodpown-site').removeClass('open');
        if($(this).closest('.js-persons-dropdown').hasClass('.js-persons-drodpown-site')) {
            $('.js-field-destination').focus();
        }
    });

    $('.js-field-destination').typeahead({
        source: [{id: 1, name: "Szeged"}, {id: 2, name: "Alaska"}]
    });

    $(document).on('click', '.js-modal-accordion', function(e) {
        $('.' + $(e.currentTarget).data('target')).slideToggle();
        e.preventDefault();
    });

    $(document).on('click', '.reservation-accordion-opener', function(e) {
        var $el = $(e.currentTarget);
        $el.children('span').toggleClass('hidden');
        if ($el.find('.detail-accordion-opener-down').hasClass('hidden')) {
            var autoHeight = $('.js-modal-accordion.info-accodion').css('height', 'auto').height();
            //$('.js-modal-accordion.info-accodion').css('height', '90px');
            $(document).find('.js-modal-accordion.info-accodion').css('height', (autoHeight + 20) + 'px');
        } else {
            $(document).find('.js-modal-accordion.info-accodion').css('height', '90px');
        }
    });

    $(document).on('click', '.js-accept-accordion', function(e) {
        $('.' + $(e.currentTarget).data('target')).slideToggle();
        e.preventDefault();
    });

    $(window).on('load scroll', function() {
        if (window.matchMedia("(min-width: 992px)").matches) {
            if($(window).scrollTop() >= 1) {
                $('body').addClass('scrolled');
            } else {
                $('body').removeClass('scrolled');
            }

        } else {
            if($(window).scrollTop() >= 1) {
                var wrapperHeight = $('.header-wrapper').height()+20;
                $('body').addClass('scrolled');
                if ($('.mobile-searchform .search-icon').hasClass('open')) {
                    $('.mobile-searchform .mobile-searchform-label').removeClass('open');
                }
                if ($('.mobile-searchform .mobile-searchform-label').hasClass('open')) {
                    $('.mobile-searchform .mobile-searchform-label').removeClass('open');
                }
                $('body.scrolled').css('padding-top', (wrapperHeight)+'px');
                /*if ($('.toggle-searchform').hasClass('open')) {
                    $('.toggle-searchform').hide('slow');
                    $('.toggle-searchform').addClass( "closed" );
                    $('.toggle-searchform').removeClass( "open" );
                }*/

            } else {
                $('body').removeClass('scrolled');
                $('body').css('padding-top', '');
            }

        }
    });

    if (window.matchMedia("(min-width: 992px)").matches) {

    } else {
        $(document).on('click', '.filter-toggle', function (e) {
            $('.filters').toggleClass('open');
            $('.filter-toggle').toggleClass('filter-open');
            $('.filters .filter-block .filter-content').hide();
            $('.filters .filter-block.open').removeClass('open');
            goToDiv('app-layout', 0);
            e.preventDefault();
        });
        $('.filters .filter-block').removeClass('open');
    }

    var config = {
        autoApply: true,
        locale: daterangePickerLocale,
        startDate: moment(),
        endDate: moment().add(1, 'd')
    };
    /**
     * STANDARD OFFER BEGIN
     */
    $('.standard-modal #arrival_date').val(config.startDate.format('YYYY-MM-DD'));
    $('.standard-modal #departure_date').val(config.endDate.format('YYYY-MM-DD'));

    $('.standard-modal #standard_arrival').daterangepicker(config);
    $('.standard-modal #standard_arrival').on('apply.daterangepicker', function(ev, picker) {
        $('.standard-modal #arrival_date').val(picker.startDate.format('YYYY-MM-DD'));
        if (picker.startDate.format('YYYY-MM-DD') != picker.endDate.format('YYYY-MM-DD')) {
            $('.standard-modal #departure_date').val(picker.endDate.format('YYYY-MM-DD'));
        } else {
            alert('A kezdeti- és a végdátum nem eshet ugyanazon napra');
            $(this).data('daterangepicker').setEndDate(moment(picker.startDate).add(1, 'd'));
        }
    });

    $('.standard-modal button.loading_on_submit').on('click', function (e) {
        e.preventDefault();

        $('.standard-modal .date-error').addClass('hidden');
        $('.standard-modal #arrival_date').parents('.form-group').removeClass('has-error');

        $('.standard-modal .alert-danger').addClass('hidden');

        $('.standard-modal .email-error').addClass('hidden');
        $('.standard-modal input[name="email"]').parents('.form-group').removeClass('has-error');

        $('.standard-modal .name-error').addClass('hidden');
        $('.standard-modal input[name="name"]').parents('.form-group').removeClass('has-error');

        $('.standard-modal .children-error').addClass('hidden');
        $('.standard-modal .children').parents('.form-group').removeClass('has-error');

        $('.standard-modal .telephone-error').addClass('hidden');
        $('.standard-modal input[name="telephone"]').parents('.form-group').removeClass('has-error');

        $('.standard-modal .accommodation-error').addClass('hidden');
        $('.standard-modal input[name="destination"]').parents('.form-group').removeClass('has-error');

        var hasError = false;

        // name error
        if (! $('.standard-modal input[name="name"]').val()) {
            hasError = true;
            $('.standard-modal .name-error').removeClass('hidden');
            $('.standard-modal input[name="name"]').parents('.form-group').addClass('has-error');
        }

        // date error
        //if (! $('.standard-modal #arrival_date').val() || ! $('.standard-modal #departure_date').val()) {
        if ($('.standard-modal #arrival_date').val() == $('.standard-modal #departure_date').val()) {
            hasError = true;
            $('.standard-modal .date-error').removeClass('hidden');
            $('.standard-modal #arrival_date').parents('.form-group').addClass('has-error');
        }

        // if there are invalid child's age
        var childrenError = false;
        $('.standard-modal .children').each(function (key, item) {
            if (! $(item).val()) {
                childrenError = true;
            }
        });
        if (childrenError) {
            hasError = true;
            $('.standard-modal .children-error').removeClass('hidden');
            $('.standard-modal .children').parents('.form-group').addClass('has-error');
        }

        // email error
        if (! $('.standard-modal input[name="email"]').val()) {
            hasError = true;
            $('.standard-modal .email-error').removeClass('hidden');
            $('.standard-modal input[name="email"]').parents('.form-group').addClass('has-error');
        }

        // telephone error
        if (! $('.standard-modal input[name="telephone"]').val()) {
            hasError = true;
            $('.standard-modal .telephone-error').removeClass('hidden');
            $('.standard-modal input[name="telephone"]').parents('.form-group').addClass('has-error');
        }

        // accommodation ID error
        if (! $('.standard-modal input[name="accommodation_id"]').val() && ! $('.standard-modal input[name="destination"]').val()) {
            hasError = true;
            $('.standard-modal .accommodation-error').removeClass('hidden');
            $('.standard-modal input[name="destination"]').parents('.form-group').addClass('has-error');
        }

        if (! hasError) {
            $('.standard-modal form').submit();

            return;
        }

        $('.standard-modal .alert-danger').removeClass('hidden');
    });

    // check if modal opener has a data property with accommodation id
    // if has we add a hidden input to the modal with the accommodation id
    $('*[data-target="#standard-offer"]').on('click', function (e) {
        var $btn = $(e.currentTarget);
        if ($btn.data('accommodation-id')) {
            $('.standard-modal form').append('<input type="hidden" name="accommodation_id" value="' + $btn.data('accommodation-id') +'">');
        }
        if ($btn.data('accommodation-name')) {
            $('.standard-modal form .destination').prop('readonly', true);
            $('.standard-modal form .destination').val($btn.data('accommodation-name').replace(/\\/g, ''));
        }
    });

    $('#standard-offer').on('hide.bs.modal', function () {
        var $standardAccommodation = $('.standard-modal form input[name="accommodation_id"]');
        if ($standardAccommodation) {
            $standardAccommodation.remove();
        }
    });
    /**
     * STANDARD OFFER END
     */

    $('input.sluggable').on('keyup', function(e) {
        var $input = $(e.currentTarget);
        $input.val(slug($input.val()));
    });

    var slug = function(str) {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();

        // remove accents, swap ñ for n, etc
        var from = "ãàáäâẽèéëêìíïîõòóöôőùúüûűñç·/,:;";
        var to   = "aaaaaeeeeeiiiioooooouuuuunc_____";
        for (var i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        str = str.replace(/[^a-z0-9_-]/g, '') // remove invalid chars
            .replace(/\s+/g, '_')
            .replace(/(--)+/g, '_'); // collapse dashes

        return str;
    };

    var $featuredTooltip = $('.featured-tooltip');
    $featuredTooltip.on('mouseout', function (e) {
        $(e.currentTarget).tooltip('hide');
    });
    $featuredTooltip.on('mouseenter', function (e) {
        var $el = $(e.currentTarget);
        var name = $el.data('name');
        var imageUrl = $el.data('image-url');
        var price = $el.data('price');
        var city = $el.data('city');
        var package = $el.data('package-name') ? $el.data('package-name') : 'Ajánlatok:';

        var $tpl = $('<div>');
        $tpl.addClass('featured-tooltip-content');
        $tpl.append('<div class="featured-tooltip-content__left"><img src="' + imageUrl + '"></div>');

        var $rightContent = $('<div>');
        $rightContent.addClass('featured-tooltip-content__right');
        $rightContent.append('<h4>' + name + '</h4>');

        if (city) {
            $rightContent.append('<small class="city">(' + city + ')</small>');
        }

        if (price) {
            $rightContent.append('<div class="price"><h5>' + package + '</h5><h4>' + price +'-tól</h4></div>');
        }

        $tpl.append($rightContent);
        $el.tooltip({
            html: true,
            template: $tpl,
            container: 'body',
            placement: 'right',
            animation: false,
            trigger: 'hover'
        }).tooltip('show');
    });

    $('.slick-slide.hidden').removeClass('hidden');

    $('.checkbox-filter-label').on('click', function (e) {
        var $el = $(e.currentTarget);

        $el.prev().find('button').click();
    });
});

function changeNumberOfChildren() {
    var html = '';
    for (var i = 1; i <= $('.standard-modal #number_of_children_standard_offer').val(); i++) {
        html += '<div class="child-ages__item">' +
            '<div class="input-group">' +
            '<span class="input-group-addon"><span>' + i + '</span>. gyerek</span>' +
            '<input type="text" autocomplete="off" name="children[]" class="form-control children">' +
            '<span class="input-group-addon">év</span>' +
            '</div>' +
            '</div>';
    }

    $('.standard_offer.child-ages').html(html);
}

function goToDiv(divId, offset) {
    var toPx = (($("#"+divId).offset().top -offset));
    $('html, body').animate({
        scrollTop: toPx
    }, 1000);
}
