$(document).ready(function() {
    $('.voucher-form').submit(function(event){
        var c_name = $('#customer_name').val();
        var c_phone = $('#customer_phone').val();
        var c_email = $('#customer_email').val();
        var c_address = $('#customer_invoice_address').val();
        if (c_name.length < 4 || c_name.length > 50) {
            alert('Helytelenül adtad meg a neved!');
            event.preventDefault();
            return 0;
        }
        if (c_phone.length < 4 || c_phone.length > 20) {
            alert('Helytelenül adtad meg a telefonszámod!');
            event.preventDefault();
            return 0;
        }
        if (c_email.length < 5 || c_email.length > 100) {
            alert('Helytelenül adtad meg az e-mail címed!');
            event.preventDefault();
            return 0;
        }
        if (c_address.length < 4) {
            alert('Helytelenül adtad meg a számlázási címed!');
            event.preventDefault();
            return 0;
        }
        return true;
    });

    $(document).on('click', '.zoom-button', function (event) {
        event.preventDefault();
    });

    $(document).on('change', '#voucher_number', function (event) {
        refreshNameList();
    });

    $(document).on('change', '#voucher_price_select', function (event) {
        if ($(this).val() == 'other') {
            $('#voucher_price').val('').removeClass('hidden');
        }
        else {
            $('#voucher_price').val(parseInt($(this).val()));
            if (!$('#voucher_price').hasClass('hidden')) {
                $('#voucher_price').addClass('hidden')
            }
        }
    });

    $(document).on('change', '.voucher', function () {
        var selected = $('.voucher:checked').val();
        $('.voucher_label').removeClass('selected');
        $('#label_'+selected).addClass('selected');
    });

    $('.slider-voucher')
        .on('init', function(slick) {
            //console.log('slider-voucher fired!');
            $('.slider-voucher').hide().css('visibility','visible').fadeIn(1000);
        })
        .slick({
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: '',
            nextArrow: '',
            autoplay: true,
            autoplaySpeed: 4000,
        });

});

var pathname = window.location.pathname;
var vouchers = [];
var urlStates = {
    'add': 'utalvany-adatok',
    'add-more': 'tovabbi-utalvany-adatok',
    'order': 'utalvany-megrendelo-adatai',
    'thankyou': 'ajandekutalvany-koszonjuk-megrendelest',
};
refreshVoucherList();

function stateChange(key) {
    history.pushState({}, '', pathname+"#"+urlStates[key]);
}

function addVoucher(skipAddMore) {
    var v_type = $('.voucher:checked').val();
    var v_label = $('.voucher:checked').data('voucher-label');
    //var name = $('#voucher_name').val();
    var price = parseInt(($('#voucher_price').val() == '')?0:$('#voucher_price').val());
    var number = $('#voucher_number option:selected').val();
    var more = $('.voucher_more:checked').val();
    var message = $('#voucher_message').val();
    var voucherNameError = false;
    var voucherNames = [];
    $('.voucher_name').each(function() {
        voucherNames.push($( this ).val());
        if ($( this ).val() == ''  || $( this ).val().length < 3 || $( this ).val().length > 80) {
            voucherNameError = true;
        }
    });

    if (v_type == null || v_type == undefined) {
        alert('Nem választottad ki az utalvány típusát!');
        return 0;
    }
    else if (price == '' || parseInt(price) == 0) {
        alert('Nem adtad meg az utalvány összegét!');
        return 0;
    }
    else if (voucherNameError) {
        alert('Hibásan adtad meg a nevet!');
        return 0;
    }
    else if (more == '' || more === undefined) {
        alert('Nem választottad ki, hogy szeretnél-e még utalványt rendelni?');
        return 0;
    }
    else {
        var cVoucher = {
            'type': v_type,
            'label': v_label,
            'name': voucherNames.join(', '),
            'nameHtml': voucherNames.join(',<br/>'),
            'price': price,
            'number': number,
            'message': message,
        };
        vouchers.push(cVoucher);
        refreshVoucherList();
        if (skipAddMore || more == 'no') {
            showVoucherDiv('order', 'order');
        }
        else {
            emptyVoucherForm();
            stateChange('add-more');
        }
        refreshVoucherOrderBtnText();
        $('.goToOrder').show();
    }
}

function showVoucherDiv(divType, state) {
    $('.voucher-div').each(function() {
        if (!$( this ).hasClass('hidden')) {
            $( this ).fadeOut(500, function() {
                $( this ).addClass('hidden').show();
                $('.voucher-'+divType).hide().removeClass('hidden').fadeIn(500);
                emptyVoucherForm();
            });
        }
    });
    stateChange(state);
    goToDiv('voucher_anchor', 130);
}

function needNewVoucher(isNeeded) {
    if (isNeeded) {
        showVoucherDiv('add', 'add-more');
    }
    else {
        showVoucherDiv('order', 'order');
    }
}

function refreshVoucherList() {
    if (!vouchers.length) {
        $('.voucher-list').hide();
        $('.goToOrder').hide();
        return;
    }

    var tbody = '';
    var tfoot = '';
    var thead = '';
    var totalCount = 0;
    var totalPrice = 0;
    thead += '<tr>';
    thead += '<th>Darab</th>';
    thead += '<th>Utalvány</th>';
    thead += '<th>Érték</th>';
    thead += '<th>Kinek</th>';
    thead += '<th>Törlés</th>';
    thead += '</tr>';
    $.each(vouchers, function (key, value) {
        totalCount = totalCount + parseInt(value.number);
        totalPrice = totalPrice + (parseInt(value.number) * parseInt(value.price));
        tbody += '<tr>';
        tbody += '<td>' + value.number + ' db <input type="hidden" name="voucher[' + key + '][number]" value="' + value.number + '" /></td>';
        tbody += '<td>' + value.label + ' <input type="hidden" name="voucher[' + key + '][label]" value="' + value.label + '" /></td>';
        tbody += '<td style="text-align: right;">' + value.price + ' Ft <input type="hidden" name="voucher[' + key + '][price]" value="' + value.price + '" /></td>';
        tbody += '<td width="35%" style="text-align: left;">' + value.nameHtml + ' <input type="hidden" name="voucher[' + key + '][name]" value="' + value.name + '" /> <input type="hidden" name="voucher[' + key + '][message]" value="' + value.message + '" />&nbsp;<span style="cursor:help;" title="'+value.message+'" class="glyphicon glyphicon-info-sign" aria-hidden="true"></span></td>';
        tbody += '<td><span style="cursor:pointer;" onclick="removeFromList(' + key + ')" class="glyphicon glyphicon-trash" aria-hidden="true"></span></td>';
        tbody += '</tr>';
    });
    tfoot += '<tr>';
    tfoot += '<td><strong>' + totalCount + ' db</strong></td>';
    tfoot += '<td>&nbsp;</td>';
    tfoot += '<td style="text-align: right;"><strong>' + totalPrice + ' Ft </strong></td>';
    tfoot += '<td>&nbsp;</td>';
    tfoot += '<td>&nbsp;</td>';
    tfoot += '</tr>';
    $('.voucher-list table thead').html(thead);
    $('.voucher-list table tbody').html(tbody);
    $('.voucher-list table tfoot').html(tfoot);
    $('.voucher-list').show();
}

function refreshNameList() {
    var number = $('#voucher_number option:selected').val();
    var nameListHtml = '';
    for (var i = 0; i < number; i++) {
        nameListHtml += '<input type="text" class="voucher_name form-control" style="margin: 5px 0;" id="voucher_name[]" placeholder="Név #'+(i+1)+'" />';
    }
    $('.voucher_name_list').html(nameListHtml);
}

function removeFromList(index) {
    vouchers.splice(index, 1);
    refreshVoucherList();
    refreshVoucherOrderBtnText();
    if (vouchers.length == 0) {
        $('.goToOrder').hide();
        showVoucherDiv('add', 'add');
    }
}

function refreshVoucherOrderBtnText() {
    var orderBtnObject = $('.orderBtn');
    if (vouchers.length > 0) {
        var vCount = 0;
        $.each(vouchers, function (key, value) {
            vCount = vCount + parseInt(value.number);
        });
        if (vouchers.length == 1) {
            //$(orderBtnObject).text('Megrendelem az utalványt ('+vCount+' db)');
            $(orderBtnObject).text('Megrendelem');
        }
        else {
            //$(orderBtnObject).text('Megrendelem az utalványokat (' + vouchers.length + ' féle, '+vCount+' db)!');
            $(orderBtnObject).text('Megrendelem');
        }
        if ($(orderBtnObject).hasClass('disabled')) $(orderBtnObject).removeClass('disabled');
    }
    else {
        $('.goToOrder').hide();
        if (!$(orderBtnObject).hasClass('disabled')) $(orderBtnObject).addClass('disabled');
        $(orderBtnObject).html("Nincs utalvány hozzáadva <span class='glyphicon glyphicon-remove-circle'></span>");
    }
}

function orderVouchers() {

}

function emptyVoucherForm() {
    $('.voucher:checked').removeAttr('checked');
    $('.voucher_more:checked').removeAttr('checked');
    $('.voucher').change();
    $('.voucher_name').val('');
    $('#voucher_price_select option:selected').removeAttr('selected');
    $('#voucher_price_select').change();
    $('#voucher_number option:selected').removeAttr('selected');
    $('#voucher_number').change();
    $('#voucher_message').val('');
}
