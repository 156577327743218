$(function () {
    $(document).on('click', '.review-form a', function() {
        var r = $(this).closest('.review-form').find('.review-form-rows > div:last').clone();
        $(this).closest('.review-form').find('.review-form-rows > div:last').after(r);
        $('input', r).val('').focus();
    });

    $(document).on('click', '.review-form-rows button', function() {
        $(this).parent().remove();
    });

    $('select.rating').barrating({
        theme: 'fontawesome-stars'
    });
});
