$(document).ready(function() {
    $.fn.datepicker.defaults.language = 'hu';
    $('.book24-form .input-group.date').datepicker({
        startView: 'years',
        format: "yyyy.mm.dd",
        weekStart: 1,
        ignoreReadonly : true,
        daysOfWeekHighlighted: "0,6",
    }).on('changeDate', function(ev){
        $(this).datepicker('hide');
    });

    $('.book24-form').submit(function (event) {
        var c_name = $('#customer_name').val();
        var c_email = $('#customer_email').val();
        var c_age = $('#age').val();
        var c_city = $('#city').val();
        if (c_name.length < 4 || c_name.length > 50) {
            alert('Helytelenül adtad meg a neved!');
            setTimeout(function(){
                $('#customer_name').focus();
            }, 1);
            event.preventDefault();
            return 0;
        }
        if (c_email.length < 5 || c_email.length > 100) {
            alert('Helytelenül adtad meg az e-mail címed!');
            setTimeout(function(){
                $('#customer_email').focus();
            }, 1);
            event.preventDefault();
            return 0;
        }
        if (c_city.length !== 0 && c_city.length < 4) {
            alert('Helytelenül adtad meg a városod!');
            setTimeout(function(){
                $('#city').focus();
            }, 1);
            event.preventDefault();
            return 0;
        }
        if (!$('.privacypolicy_book24').is(':checked')) {
            alert('Nem fogadtad el az Adatvédelmi Nyilatkozatot!');
            setTimeout(function(){
                $('.privacypolicy_book24').focus();
            }, 1);
            event.preventDefault();
            return 0;
        }
        $('.book24submit').prop('disabled', true);
        return true;
    });
});

